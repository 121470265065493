import React, { Dispatch, useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import { Box, Typography, IconButton } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormattedMessage, useIntl } from 'react-intl'
import Slider from 'react-slick'
import { LeftArrow, RightArrow } from 'components/contents/common/Arrows'
import { trackWidgetShown, trackException, trackEvents } from 'utils/tracking'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import { IPeopleResult } from 'components/models/PeopleResult'
import LeftPeopleWidgetResult from './LeftPeopleWidgetResult'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import FilterStore from 'store/Filters'
import { WidgetKey } from 'constants/widgets'
import createDOMPurify from 'dompurify'

export interface LeftPeopleWidgetProps {
  query: string
  peopleResults: Array<IPeopleResult>
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeftPeopleWidgetProps

function LeftPeopleWidget(props: AllProps): JSX.Element {
  const {
    peopleResults,
    deviceSettings,
    currentFilters,
    query,
    clearTemporaryFilter
  } = props

  const leftWidgetClasses = getStylesLeftWidget()
  const [slideIndex, setSlideIndex] = useState(0)
  const intl = useIntl()
  const DOMPurify = createDOMPurify(window)

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.people,
      widgetPane: 'left'
    })
  }, [])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    dragable: true,
    nextArrow:
      slideIndex + 3 >= peopleResults.length || deviceSettings.renderMobile ? (
        <></>
      ) : (
        <RightArrow />
      ),
    prevArrow:
      slideIndex === 0 || deviceSettings.renderMobile ? <></> : <LeftArrow />,
    afterChange: (newIndex: any) => setSlideIndex(newIndex)
  }

  let slides: JSX.Element[] = []
  try {
    slides = peopleResults.map((peopleResult: IPeopleResult, i) => (
      <LeftPeopleWidgetResult
        peopleResult={peopleResult}
        i={i}
        deviceSettings={deviceSettings}
      />
    ))
  } catch (error) {
    trackException('Error in parsing slides in ResultsPeopleFrame.tsx', error)
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box style={{ flex: '0 0 10px' }} />)
  }

  const seeAllLink = `/results/people/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            tabIndex={-1} //focus IconButton
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.people,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })

              clearTemporaryFilter()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.people,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon></ArrowForwardIcon>
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  return (
    <Box id={'con-widget-left-people'} className={leftWidgetClasses.container}>
      {peopleResults && peopleResults.length > 0 && (
        <>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.people,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })

              clearTemporaryFilter()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.people,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h6"
              color="primary"
              component="p"
              className={leftWidgetClasses.link}
              gutterBottom
            >
              {intl.formatMessage({
                id: 'experts_in',
                defaultMessage: 'Related Profiles'
              })}
            </Typography>
          </RouterLink>
          <Box className={leftWidgetClasses.cardContainer}>
            {!deviceSettings.renderMobile && slides.length > 0 && (
              <Slider className={leftWidgetClasses.slider} {...settings}>
                {slides}
              </Slider>
            )}
            {deviceSettings.renderMobile && slides.length > 0 && (
              <Box className={leftWidgetClasses.mobileScrollContainer}>
                {slides}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPeopleWidget)
