import React, { useState, useEffect, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import SeeAllButton from './SeeAllButton'
import { trackWidgetShown } from 'utils/tracking'
import { WidgetKey } from 'constants/widgets'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import { IPeopleResult } from 'components/models/PeopleResult'
import RightPeopleWidgetResult from './RightPeopleWidgetResult'
import createDOMPurify from 'dompurify'
import FilterStore from 'store/Filters'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface RightPeopleWidgetProps {
  searchQuery: any
  peopleResults: Array<IPeopleResult>
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightPeopleWidgetProps

function RightPeopleWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    peopleResults,
    deviceSettings,
    currentDataSources,
    currentFilters,
    clearTemporaryFilter,
    synonymsApplied
  } = props

  const DOMPurify = createDOMPurify(window)

  const rightWidgetClasses = getStylesRightWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.people,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'experts_in',
    defaultMessage: 'Related Profiles'
  })

  const seeAllLink = `/results/people/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  useEffect(() => {
    const newsDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === WidgetKey.people
    )
    const newsDatasourceEnabled = newsDatasource
      ? newsDatasource.enabled
      : false

    setDataSourceEnabled(newsDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  return (
    <Box
      id={'con-widget-right-people'}
      className={
        peopleResults.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {peopleResults
        .slice(0, itemsShown)
        .map((peopleResult: IPeopleResult, index: number) => (
          <RightPeopleWidgetResult
            peopleResult={peopleResult}
            index={index}
            deviceSettings={deviceSettings}
            searchQuery={DOMPurify.sanitize(searchQuery)}
            synonymsApplied={synonymsApplied}
          />
        ))}
      {peopleResults.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {dataSourceEnabled && peopleResults.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.people}
              onClickCallback={clearTemporaryFilter}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAll
                  : rightWidgetClasses.arrowContainer
              }
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAllExpanded
                  : rightWidgetClasses.arrowContainerExpanded
              }
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightPeopleWidget)
