import React, { useState, useEffect, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { WidgetKey } from 'constants/widgets'
import FilePlaceholder from 'images/file_placeholder.png'
import SeeAllButton from './SeeAllButton'
import { getStylesRightKPMGWebsitesWidget } from 'styles/contents/resultpages/widgets/RightKPMGWebsitesWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import FilterStore from 'store/Filters'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IKpmgWebsitesResult } from 'components/models/KpmgWebsiteResult'

export interface RightKPMGWebsitesWidgetProps {
  searchQuery: any
  results: IKpmgWebsitesResult[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightKPMGWebsitesWidgetProps

function RightKPMGWebsitesWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    currentDataSources,
    currentFilters,
    clearTemporaryFilter
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightKPMGWebsitesWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  const intl = useIntl()

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.kpmgWebsites,
      widgetPane: 'right'
    })
  }, [])

  const imageClick = (e: any, linkUrl: any) => {
    if (deviceSettings.openLinksInNewTab === true) {
      window.open(linkUrl)
    } else {
      window.location.href = linkUrl
    }
  }

  const setPlaceholderImage = (e: any) => {
    e.target.src = FilePlaceholder
  }

  const headline = intl.formatMessage({
    id: 'kmpgWebsites_on',
    defaultMessage: 'kpmg.com related'
  })

  useEffect(() => {
    const kpmgWebsitesDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === 'kpmg.com'
    )
    const kpmgWebsitesDatasourceEnabled = kpmgWebsitesDatasource
      ? kpmgWebsitesDatasource.enabled
      : false

    setDataSourceEnabled(kpmgWebsitesDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  const seeAllLink = `/results/aem/${getUrlParameterForCurrentFilters(
    currentFilters,
    [{ key: 'page', value: '1' }]
  )}`

  return (
    <Box
      id={'con-widget-right-kpmg'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results
        .slice(0, itemsShown)
        .map((item: IKpmgWebsitesResult, index: number) => {
          const imageUrl = item.image ? DOMPurify.sanitize(item.image) : ''
          let smallImageUrl = imageUrl
            ? `${
                imageUrl.includes('.jpg') ? imageUrl.split('.jpg')[0] : imageUrl
              }/jcr:content/renditions/cq5dam.web.237.158.jpg`
            : ''
          if (DOMPurify.sanitize(item.tabType).toLowerCase() === 'people') {
            smallImageUrl = smallImageUrl.replace(
              '.237.158.jpg',
              '.237.237.jpg'
            )
          }
          return (
            //<LazyLoad offset={150}>
            <Box key={'rkpmg' + index}>
              <Box key={'rkpmgb1' + index} className={classes.widgetContent}>
                <Box key={'rkpmgb2' + index} className={classes.widgetText}>
                  <Link
                    underline={'hover'}
                    key={'rkpmgl1' + index}
                    onClick={() => {
                      removeQueryParam(navigateFunction, 'cntx')
                      trackRightWidgetClick({
                        title: DOMPurify.sanitize(item.title),
                        url: DOMPurify.sanitize(item.url),
                        index,
                        widgetKey: WidgetKey.kpmgWebsites,
                        body: DOMPurify.sanitize(item.body)
                      })
                    }}
                    {...(!deviceSettings.isMobile
                      ? {
                          onAuxClick: (event: any) => {
                            removeQueryParam(navigateFunction, 'cntx')
                            trackRightWidgetClick({
                              title: DOMPurify.sanitize(item.title),
                              url: DOMPurify.sanitize(item.url),
                              index,
                              widgetKey: WidgetKey.kpmgWebsites,
                              body: DOMPurify.sanitize(item.body),
                              wasRightClicked:
                                event?.button === 2 ? true : false
                            })
                          }
                        }
                      : {})}
                    href={DOMPurify.sanitize(item.url)}
                    data-node-title={DOMPurify.sanitize(item.title)}
                    data-node-index={index}
                    data-node-click-type="rightWidgetClick"
                    data-node-widget-key={WidgetKey.kpmgWebsites}
                    {...(deviceSettings.openLinksInNewTab === true
                      ? { target: '_blank', rel: 'noreferrer' }
                      : {})}
                  >
                    <TooltipTitle
                      title={DOMPurify.sanitize(item.title?.trim())}
                      key={'rkpmgd1' + index}
                      singleLine={true}
                      additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
                    />
                  </Link>
                  <ResponsiveHTMLEllipsis
                    className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionExtended}`}
                    basedOn="words"
                    unsafeHTML={highlightText(
                      DOMPurify.sanitize(item.body),
                      prepareHighlightWords(
                        DOMPurify.sanitize(searchQuery),
                        DOMPurify.sanitize(item.body),
                        []
                      ),
                      true
                    )}
                    maxLine="2"
                  />
                  <Typography
                    key={'rkpmgt' + index}
                    className={rightWidgetClasses.widgetFooterContent}
                  >
                    {DOMPurify.sanitize(item.tabType)}
                  </Typography>
                </Box>
                <Box
                  key={'rkpmgb3' + index}
                  className={rightWidgetClasses.spacer}
                />
                <div
                  key={'rkpmgd3' + index}
                  style={{
                    backgroundImage: imageUrl
                      ? `url("${smallImageUrl}"),url("${imageUrl}"),url("${FilePlaceholder}")`
                      : `url("${FilePlaceholder}")`
                  }}
                  className={classes.widgetImage}
                  onClick={(e) => {
                    imageClick(e, DOMPurify.sanitize(item.image))
                  }}
                  onError={(e) => {
                    setPlaceholderImage(e)
                  }}
                />
              </Box>
            </Box>
            //</LazyLoad>
          )
        })}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {dataSourceEnabled && results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.kpmgWebsites}
              onClickCallback={clearTemporaryFilter}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAll
                  : rightWidgetClasses.arrowContainer
              }
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAllExpanded
                  : rightWidgetClasses.arrowContainerExpanded
              }
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightKPMGWebsitesWidget)
