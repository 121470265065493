import React, { Dispatch, useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import FilterStore from 'store/Filters'
// import LazyLoad from 'react-lazyload'
import { Box, Typography, IconButton } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormattedMessage, useIntl } from 'react-intl'
import Slider from 'react-slick'
import { LeftArrow, RightArrow } from 'components/contents/common/Arrows'
import {
  trackWidgetShown,
  trackException,
  trackLeftWidgetClick,
  trackEvents
} from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { WidgetKey } from 'constants/widgets'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import StatisticsPlaceHolder from 'images/kpmg_placeholder.png'
import { IStatisticResult } from 'components/models/StatisticResult'

export interface LeftStatisticsWidgetProps {
  results: IStatisticResult[]
  query: string
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeftStatisticsWidgetProps

function LeftStatisticsWidget(props: AllProps): JSX.Element {
  const {
    results,
    deviceSettings,
    currentFilters,
    clearTemporaryFilter,
    query
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()

  const [slideIndex, setSlideIndex] = useState(0)
  const intl = useIntl()

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    dragable: true,
    nextArrow:
      slideIndex + 3 >= results.length || deviceSettings.renderMobile ? (
        <></>
      ) : (
        <RightArrow />
      ),
    prevArrow:
      slideIndex === 0 || deviceSettings.renderMobile ? <></> : <LeftArrow />,
    afterChange: (newIndex: any) => setSlideIndex(newIndex)
  }

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.statistics,
      widgetPane: 'left'
    })
  }, [])

  let slides: JSX.Element[] = []
  try {
    slides = results.map((item: IStatisticResult, i) => (
      // <LazyLoad offset={150}>
      <Box key={'ls' + i}>
        <Box key={'lsb1' + i} style={{ width: 20 }} />
        <Link
          key={'lsl' + i}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackLeftWidgetClick({
              title: DOMPurify.sanitize(item.title),
              url: DOMPurify.sanitize(item.link),
              widgetKey: WidgetKey.statistics,
              index: i
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackLeftWidgetClick({
                    title: DOMPurify.sanitize(item.title),
                    url: DOMPurify.sanitize(item.link),
                    widgetKey: WidgetKey.statistics,
                    index: i,
                    wasRightClicked: event?.button === 2 ? true : false
                  })
                }
              }
            : {})}
          href={DOMPurify.sanitize(item.link)}
          className={leftWidgetClasses.cardLink}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <Card key={'lsc' + i} className={leftWidgetClasses.card}>
            <CardMedia
              key={'lscm' + i}
              className={leftWidgetClasses.media}
              component="div"
              style={{
                backgroundImage: item.image
                  ? `url("${DOMPurify.sanitize(
                      item.image
                    )}"),url("${StatisticsPlaceHolder}")`
                  : `url("${StatisticsPlaceHolder}")`,
                backgroundColor: '#7787a1',
                backgroundPosition: 'top'
              }}
            ></CardMedia>
            <CardContent
              key={'lscc' + i}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                key={'lst1' + i}
                variant="body1"
                color="primary"
                component="div"
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(item.title?.trim())}
                  key={'lsd1' + i}
                />
              </Typography>
              <Box key={'lsb4' + i} className={leftWidgetClasses.spacer} />
              <Typography
                key={'lst2' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{ marginTop: 10, fontStyle: 'italic' }}
              >
                {intl.formatMessage({ id: 'origin', defaultMessage: 'Source' })}
                :{' '}
                {intl.formatMessage({
                  id: 'statista',
                  defaultMessage: 'Statista'
                })}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Box>
      // </LazyLoad>
    ))
  } catch (error) {
    trackException(
      'Error in parsing slides in ResultsStatisticsFrame.tsx',
      error
    )
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box key="mobile" style={{ flex: '0 0 10px' }} />)
  }

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            tabIndex={-1} //focus IconButton
            to={`/results/statistics/${getUrlParameterForCurrentFilters(
              currentFilters,
              [{ key: 'page', value: '1' }]
            )}`}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.statistics,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })

              clearTemporaryFilter()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.statistics,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon></ArrowForwardIcon>
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  return (
    <Box
      id={'con-widget-left-statistics'}
      className={leftWidgetClasses.container}
    >
      {results && results.length > 0 && (
        <RouterLink
          to={`/results/statistics/${getUrlParameterForCurrentFilters(
            currentFilters,
            [{ key: 'page', value: '1' }]
          )}`}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            trackEvents('widget-see-all', {
              widgetType: WidgetKey.statistics,
              searchTerm: DOMPurify.sanitize(query),
              wasRightClicked: false
            })

            clearTemporaryFilter()
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  trackEvents('widget-see-all', {
                    widgetType: WidgetKey.statistics,
                    searchTerm: DOMPurify.sanitize(query),
                    wasRightClicked: event && event.button === 2 ? true : false
                  })
                }
              }
            : {})}
        >
          <Typography
            variant="h6"
            color="primary"
            component="p"
            className={leftWidgetClasses.link}
            gutterBottom
          >
            {intl.formatMessage({
              id: 'statistics_on',
              defaultMessage: 'Related Statistics'
            })}
          </Typography>
        </RouterLink>
      )}
      <Box className={leftWidgetClasses.cardContainer}>
        {!deviceSettings.renderMobile && slides.length > 0 && (
          <Slider className={leftWidgetClasses.slider} {...settings}>
            {slides}
          </Slider>
        )}
        {deviceSettings.renderMobile && slides.length > 0 && (
          <Box className={leftWidgetClasses.mobileScrollContainer}>
            {slides}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentFilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftStatisticsWidget)
