import React, { useState } from 'react'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Store } from 'store'
import { Box, Grid } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { trackWidgetShown } from 'utils/tracking'
import { WidgetKey } from 'constants/widgets'
import SeeAllButton from './SeeAllButton'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IVivaEngageResult } from 'components/models/VivaEngageResult'
import RightVivaEngageWidgetResult from './RightVivaEngageWidgetResult'
import AuthStore from 'store/Auth'

export interface RightVivaEngageWidgetProps {
  searchQuery: any
  results: IVivaEngageResult[]
}

type AllProps = ReturnType<typeof mapStateToProps> & RightVivaEngageWidgetProps

function RightVivaEngageWidget(props: AllProps): JSX.Element {
  const { searchQuery, results, deviceSettings, aadInfo, findConfiguration } =
    props

  const DOMPurify = createDOMPurify(window)

  const rightWidgetClasses = getStylesRightWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.vivaengage,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'vivaengage_on',
    defaultMessage: 'Viva Engage'
  })

  // If widget prefiltering is enabled, add it to the see all link
  const seeAllLink =
    findConfiguration &&
    findConfiguration.SeeAllLinks &&
    findConfiguration.SeeAllLinks[WidgetKey.vivaengage]
      ? findConfiguration.SeeAllLinks[WidgetKey.vivaengage]
      : `https://web.yammer.com/main/feed`

  return (
    <Box
      id={'con-widget-right-vivaengage'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results
        .slice(0, itemsShown)
        .map((item: IVivaEngageResult, index: number) => (
          <RightVivaEngageWidgetResult
            item={item}
            deviceSettings={deviceSettings}
            index={index}
            aadInfo={aadInfo}
          />
        ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.vivaengage}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={rightWidgetClasses.arrowContainerSeeAll}
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={rightWidgetClasses.arrowContainerSeeAllExpanded}
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

export default connect(mapStateToProps)(RightVivaEngageWidget)
