import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Store } from 'store'
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import UserSettingsStore from 'store/UserSettings'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { WidgetKey } from 'constants/widgets'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getStylesRightMetricsWidget } from 'styles/contents/resultpages/widgets/RightMetricsWidget'
import createDOMPurify from 'dompurify'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { MetricsResult, TopSearch } from 'components/models/MetricsResult'
import SeeAllButton from './SeeAllButton'
import { useFilter } from 'utils/filters'
import SearchIcon from '@mui/icons-material/Search'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AuthStore from 'store/Auth'
import {
  MetricsSeries,
  SingleSeriesResult
} from 'components/models/MetricsSeries'
import { getSeriesMetricsResults } from 'utils/widgets/rightMetricsWidgetSeriesFetch'
import { LineChart } from '@mui/x-charts/LineChart'
import { AxisConfig } from '@mui/x-charts/models/axis'
import { MakeOptional } from '@mui/x-charts/models/helpers'
import { LineSeriesType } from '@mui/x-charts/models/seriesType'
import SettingsStore from 'store/Settings'

export interface RightMetricsWidgetProps {
  result: MetricsResult
  searchQuery: string
}

type AllProps = ReturnType<typeof mapStateToProps> & RightMetricsWidgetProps

function RightMetricsWidget(props: AllProps): JSX.Element {
  const { result, searchQuery, userSettings, aadInfo, findConfiguration } =
    props

  const [, setSearchQuery] = useFilter('q', '')
  const [seriesLoading, setSeriesLoading] = useState(true)
  const [seriesResults, setSeriesResults] = useState<{
    xAxis: any[]
    series: any[]
    yAxis: any[]
  } | null>(null)

  const DOMPurify = createDOMPurify(window)

  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightMetricsWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.metrics,
      widgetPane: 'right'
    })

    getSeriesMetricsResults(aadInfo, userSettings, true).then(
      (result: MetricsSeries | null) => {
        if (result && result.SeriesMetric) {
          try {
            const minValue = result.SeriesMetric.reduce(function (
              a: SingleSeriesResult,
              b: SingleSeriesResult
            ) {
              return a.Date < b.Date ? a : b
            })

            const maxValue = result.SeriesMetric.reduce(function (
              a: SingleSeriesResult,
              b: SingleSeriesResult
            ) {
              return a.Date > b.Date ? a : b
            })

            let minDate: Date | undefined = undefined
            let maxDate: Date | undefined = undefined

            if (minValue && minValue.Date) {
              const offset = minValue.Date.getTimezoneOffset()
              minDate = new Date(minValue.Date.getTime() - offset * 60 * 1000)
            }

            if (maxValue && maxValue.Date) {
              const offset = maxValue.Date.getTimezoneOffset()
              maxDate = new Date(maxValue.Date.getTime() - offset * 60 * 1000)
            }

            const xAxis: MakeOptional<AxisConfig, 'id'>[] = [
              {
                id: 'date',
                data: [],
                scaleType: 'utc',
                tickMinStep: 3600 * 1000 * 24,
                label: intl.formatMessage({
                  id: 'metrics_series_label',
                  defaultMessage: 'Total searches / users last 3 months'
                }),
                min: minDate,
                max: maxDate,
                hideTooltip: true,
                valueFormatter: (value: string) => {
                  let result = value
                  try {
                    result = new Date(value).toLocaleDateString(
                      userSettings.Language ? userSettings.Language : 'en',
                      {
                        month: 'short',
                        day: 'numeric'
                      }
                    )
                  } catch {}

                  return result
                }
              }
            ]
            const series: MakeOptional<LineSeriesType, 'type'>[] = [
              {
                id: 'searches',
                data: [],
                label: intl.formatMessage({
                  id: 'metrics_total_searches',
                  defaultMessage: 'Total searches'
                }),
                color: '#0078d4',
                yAxisKey: 'linearAxis'
              },
              {
                id: 'users',
                data: [],
                label: intl.formatMessage({
                  id: 'metrics_total_users',
                  defaultMessage: 'Total users'
                }),
                color: '#71afe5',
                yAxisKey: 'linearAxis'
              }
            ]

            result.SeriesMetric.forEach((item: SingleSeriesResult) => {
              const date = new Date(item.Date)
              let dateClean = date
              if (item.Date) {
                const offset = date.getTimezoneOffset()
                dateClean = new Date(date.getTime() - offset * 60 * 1000)
              }
              xAxis[0].data?.push(dateClean)
              series[0].data?.push(item.TotalSearches)
              series[1].data?.push(item.TotalUsers)
            })

            const data = {
              xAxis: xAxis,
              yAxis: [{ id: 'linearAxis', scaleType: 'log' }],
              series: series
            }

            setSeriesResults(data)
          } catch {}
        }

        setSeriesLoading(false)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const intl = useIntl()

  // Triggers the new search
  const setNewSearchQuery: any = (
    newSearchQuery: string,
    currentSearchTerm: string
  ) => {
    if (!newSearchQuery || newSearchQuery === currentSearchTerm) return
    setSearchQuery(newSearchQuery)
  }

  const headline = intl.formatMessage({
    id: 'metrics_on',
    defaultMessage: 'Metrics in'
  })

  const seeAllLink =
    findConfiguration &&
    findConfiguration.SeeAllLinks &&
    findConfiguration.SeeAllLinks[WidgetKey.metrics]
      ? findConfiguration.SeeAllLinks[WidgetKey.metrics]
      : `https://app.powerbi.com/groups/me/reports/40f33a4b-d30b-4566-baca-3f18ba012e1d/ReportSection8dae7197a0d1245c125e?chromeless=1&experience=power-bi`

  return (
    <Box
      id={'con-widget-right-metrics'}
      className={rightWidgetClasses.widgetArea}
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline + ' ' + userSettings.Country}
          maxLine="2"
        />
      </h2>
      <div>
        {seriesLoading && (
          <div className={classes.metricSeriesContainer}>
            <CircularProgress size={25} />
          </div>
        )}
        {!seriesLoading && seriesResults && (
          <div
            className={classes.metricSeriesContainer}
            style={{ overflow: 'hidden' }}
          >
            <LineChart
              xAxis={seriesResults.xAxis}
              yAxis={seriesResults.yAxis}
              series={seriesResults.series}
              width={400}
              height={180}
              slotProps={{ legend: { hidden: true } }}
              margin={{ right: 35 }}
              leftAxis="linearAxis"
              disableAxisListener={true}
            />
          </div>
        )}
      </div>
      <div className={classes.searchMetricContainer}>
        <div>
          <div className={classes.searchMetricItem}>
            {intl.formatMessage({
              id: 'metrics_total_searches',
              defaultMessage: 'Total Searches'
            })}
          </div>
          <div className={classes.searchMetricItem}>{result.TotalSearches}</div>
        </div>
        <div>
          <div className={classes.searchMetricItem}>
            {intl.formatMessage({
              id: 'metrics_total_sessions',
              defaultMessage: 'Total Sessions'
            })}
          </div>
          <div className={classes.searchMetricItem}>{result.TotalSessions}</div>
        </div>
        <div>
          <div className={classes.searchMetricItem}>
            {intl.formatMessage({
              id: 'metrics_total_users',
              defaultMessage: 'Total Users'
            })}
          </div>
          <div className={classes.searchMetricItem}>{result.TotalUsers}</div>
        </div>
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow key={'topSearches'}>
              <TableCell colSpan={2} className={classes.titleCell} scope="row">
                {intl.formatMessage({
                  id: 'metrics_top_searches',
                  defaultMessage: 'Top searches'
                }) + ':'}
              </TableCell>
            </TableRow>
            {result.TopSearches.map((topSearch: TopSearch, index: number) => (
              <TableRow
                key={DOMPurify.sanitize(topSearch.searchTerm)}
                className={classes.row}
              >
                <TableCell
                  colSpan={2}
                  className={classes.contentCell}
                  scope="row"
                >
                  <Box
                    key={'rst' + index}
                    className={classes.widgetItem}
                    tabIndex={0}
                    onClick={() => {
                      trackRightWidgetClick({
                        title: DOMPurify.sanitize(topSearch.searchTerm),
                        url: '',
                        index,
                        widgetKey: WidgetKey.searchTop10.toLowerCase()
                      })
                      setNewSearchQuery(
                        DOMPurify.sanitize(topSearch.searchTerm),
                        DOMPurify.sanitize(searchQuery)
                      )
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        trackRightWidgetClick({
                          title: DOMPurify.sanitize(topSearch.searchTerm),
                          url: '',
                          index,
                          widgetKey: WidgetKey.searchTop10.toLowerCase()
                        })
                        setNewSearchQuery(
                          DOMPurify.sanitize(topSearch.searchTerm),
                          DOMPurify.sanitize(searchQuery)
                        )
                      }
                    }}
                  >
                    <SearchIcon
                      key={'rstsi' + index}
                      className={classes.widgetItemIcon}
                    />
                    <Box key={'rstb2' + index}>
                      <ResponsiveEllipsis
                        className={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
                        basedOn="words"
                        text={`${DOMPurify.sanitize(
                          topSearch.searchTerm
                        )} (${DOMPurify.sanitize(topSearch.count)})`}
                        maxLine="1"
                      />
                    </Box>
                    <Box
                      key={'rstb3' + index}
                      className={`${rightWidgetClasses.spacer} ${classes.spacer} `}
                    />
                    <Box key={'rstb4' + index} className={classes.widgetArrow}>
                      <ArrowForwardIosIcon className={classes.widgetItemIcon} />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={rightWidgetClasses.footerGrid}
      >
        <SeeAllButton
          searchQuery={''}
          url={seeAllLink}
          widgetKey="metrics"
          defaultText={
            <FormattedMessage
              id="metrics_see_all"
              defaultMessage="See all Metrics"
            />
          }
        />
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

export default connect(mapStateToProps)(RightMetricsWidget)
