import React, { useState, useEffect } from 'react'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Store } from 'store'
import { Box, Grid } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import {
  trackWidgetShown,
  trackException,
  trackRightWidgetClick
} from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import SeeAllButton from './SeeAllButton'
import { getStylesRightNewsWidget } from 'styles/contents/resultpages/widgets/RightNewsWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import FilterStore from 'store/Filters'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { INewsResult } from 'components/models/NewsResult'

export interface RightNewsWidgetProps {
  searchQuery: any
  results: INewsResult[]
  synonymsApplied: ISynonymsApplied[]
}

/**
 * Formats the news items top line to be displayed in UI
 * @param dates The dates
 * @param sources The sources
 * @return a formatted top line for all news items
 */
function formatNewsTopLine(date: string, source: string): string {
  try {
    let newsTopLine = date
    if (date && date.length > 0 && source && source.length > 0) {
      newsTopLine += ' > '
    }
    newsTopLine += source
    return newsTopLine
  } catch (error) {
    trackException('Error formating News right widget top line', error)
  }

  return ''
}

type AllProps = ReturnType<typeof mapStateToProps> & RightNewsWidgetProps

function RightNewsWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    currentDataSources,
    currentfilters,
    synonymsApplied
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightNewsWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.news,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'news_on',
    defaultMessage: 'News in'
  })

  const seeAllLink = `/results/news/${getUrlParameterForCurrentFilters(
    currentfilters,
    [{ key: 'page', value: '1' }]
  )}`

  useEffect(() => {
    const newsDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === WidgetKey.news
    )
    const newsDatasourceEnabled = newsDatasource
      ? newsDatasource.enabled
      : false

    setDataSourceEnabled(newsDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  return (
    <Box
      id={'con-widget-right-news'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results.slice(0, itemsShown).map((item: INewsResult, index: number) => (
        <Box key={'rn' + index} className={classes.widgetText}>
          <Link
            underline={'hover'}
            key={'rnl1' + index}
            onClick={() => {
              removeQueryParam(navigateFunction, 'cntx')
              trackRightWidgetClick({
                title: DOMPurify.sanitize(item.Title),
                url: DOMPurify.sanitize(item.Link),
                index,
                widgetKey: WidgetKey.news,
                body: DOMPurify.sanitize(item.Summary)
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    removeQueryParam(navigateFunction, 'cntx')
                    trackRightWidgetClick({
                      title: DOMPurify.sanitize(item.Title),
                      url: DOMPurify.sanitize(item.Link),
                      index,
                      widgetKey: WidgetKey.news,
                      body: DOMPurify.sanitize(item.Summary),
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
            href={DOMPurify.sanitize(item.Link)}
            data-node-title={DOMPurify.sanitize(item.Title)}
            data-node-index={index}
            data-node-click-type="rightWidgetClick"
            data-node-widget-key={WidgetKey.news}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <TooltipTitle
              title={DOMPurify.sanitize(item.Title?.trim())}
              key={'rnd1' + index}
              singleLine={true}
              additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
            />
          </Link>
          <ResponsiveHTMLEllipsis
            className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionExtended} ${classes.widgetDescription}`}
            basedOn="words"
            unsafeHTML={highlightText(
              DOMPurify.sanitize(item.Summary?.trim()),
              prepareHighlightWords(
                DOMPurify.sanitize(searchQuery),
                DOMPurify.sanitize(item.Summary?.trim()),
                synonymsApplied
              ),
              true
            )}
            maxLine="2"
          />
          <ResponsiveEllipsis
            className={rightWidgetClasses.widgetFooter}
            basedOn="words"
            text={DOMPurify.sanitize(
              formatNewsTopLine(
                item.Date
                  ? intl.formatDate(
                      DOMPurify.sanitize(item.Date),
                      dateFormatOptions
                    )
                  : '',
                item.Source ? DOMPurify.sanitize(item.Source) : ''
              )?.trim()
            )}
            maxLine="1"
          />
        </Box>
      ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {dataSourceEnabled && results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.news}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAll
                  : rightWidgetClasses.arrowContainer
              }
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAllExpanded
                  : rightWidgetClasses.arrowContainerExpanded
              }
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

export default connect(mapStateToProps)(RightNewsWidget)
