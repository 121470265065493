import React, { useState, Dispatch } from 'react'
import SettingsStore from 'store/Settings'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Store } from 'store'
import { Box, Grid } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import UserSettingsStore from 'store/UserSettings'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import {
  trackWidgetShown,
  trackException,
  trackRightWidgetClick
} from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import SeeAllButton from './SeeAllButton'
import { getStylesRightNewsWidget } from 'styles/contents/resultpages/widgets/RightNewsWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import FilterStore from 'store/Filters'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IKCIPResult } from 'components/models/KCIPResult'

export interface RightKCIPWidgetProps {
  searchQuery: any
  results: IKCIPResult[]
  synonymsApplied: ISynonymsApplied[]
}

/**
 * Formats the kcip items top line to be displayed in UI
 * @param updates The last update dates
 * @param organizations The organizations
 * @return a formatted top line for all kcip items
 */
function formatKCIPTopLine(update: string, organization: string): string {
  try {
    let kcipTopLine = update

    if (
      update &&
      update.length > 0 &&
      organization &&
      organization.length > 0
    ) {
      kcipTopLine += ' > '
    }

    kcipTopLine += organization

    return kcipTopLine
  } catch (error) {
    trackException('Error formating KCIP right widget top line', error)
  }

  return ''
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightKCIPWidgetProps

function RightKCIPWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    synonymsApplied,
    findConfiguration
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightNewsWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.kcip,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'kcip_on',
    defaultMessage: 'Related KPMG Code'
  })

  const seeAllLink =
    findConfiguration &&
    findConfiguration.SeeAllLinks &&
    findConfiguration.SeeAllLinks[WidgetKey.kcip]
      ? findConfiguration.SeeAllLinks[WidgetKey.kcip]
      : 'https://portal.code.kpmg.com'

  return (
    <Box
      id={'con-widget-right-kcip'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results.slice(0, itemsShown).map((item: IKCIPResult, index: number) => (
        <Box key={'rn' + index} className={classes.widgetText}>
          <Link
            underline={'hover'}
            key={'rnl1' + index}
            onClick={() => {
              removeQueryParam(navigateFunction, 'cntx')
              trackRightWidgetClick({
                title: DOMPurify.sanitize(item.name),
                url: DOMPurify.sanitize(item.url),
                index,
                widgetKey: WidgetKey.kcip,
                body: DOMPurify.sanitize(item.description)
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    removeQueryParam(navigateFunction, 'cntx')
                    trackRightWidgetClick({
                      title: DOMPurify.sanitize(item.name),
                      url: DOMPurify.sanitize(item.url),
                      index,
                      widgetKey: WidgetKey.news,
                      body: DOMPurify.sanitize(item.description),
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
            href={DOMPurify.sanitize(item.url)}
            data-node-title={DOMPurify.sanitize(item.name)}
            data-node-index={index}
            data-node-click-type="rightWidgetClick"
            data-node-widget-key={WidgetKey.kcip}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <TooltipTitle
              title={DOMPurify.sanitize(item.name?.trim())}
              key={'rnd1' + index}
              singleLine={true}
              additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
            />
          </Link>
          <ResponsiveHTMLEllipsis
            className={`${rightWidgetClasses.widgetDescription} ${rightWidgetClasses.widgetDescriptionThreeLines} ${rightWidgetClasses.widgetDescriptionExtended} ${classes.widgetDescription}`}
            basedOn="words"
            unsafeHTML={highlightText(
              DOMPurify.sanitize(item.description?.trim()),
              prepareHighlightWords(
                DOMPurify.sanitize(searchQuery),
                DOMPurify.sanitize(item.description?.trim()),
                synonymsApplied
              ),
              true
            )}
            maxLine="3"
          />
          <ResponsiveEllipsis
            className={rightWidgetClasses.widgetFooter}
            basedOn="words"
            text={DOMPurify.sanitize(
              formatKCIPTopLine(
                item.update
                  ? intl.formatDate(
                      DOMPurify.sanitize(item.update),
                      dateFormatOptions
                    )
                  : '',
                item.organization ? DOMPurify.sanitize(item.organization) : ''
              )?.trim()
            )}
            maxLine="1"
          />
        </Box>
      ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.kcip}
            />
          )}
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={rightWidgetClasses.arrowContainerSeeAll}
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={rightWidgetClasses.arrowContainerSeeAllExpanded}
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter()),
    setTemporaryFilter: (currentFilter: CurrentFilter) =>
      dispatch(FilterStore.actions.setTemporaryFilter(currentFilter))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightKCIPWidget)
