import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Grid } from '@mui/material'
import Link from '@mui/material/Link'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { useNavigate } from 'react-router-dom'
import { WidgetKey } from 'constants/widgets'
import { removeQueryParam } from 'utils/queryParams'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { getStylesRightMeercatWidget } from 'styles/contents/resultpages/widgets/RightMeercatWidget'
import SeeAllButton from './SeeAllButton'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IMeercatResult } from 'components/models/MeercatResult'

export interface RightMeercatWidgetProps {
  searchQuery: any
  meercatResults: IMeercatResult[]
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> & RightMeercatWidgetProps

function RightMeercatWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    meercatResults,
    deviceSettings,
    synonymsApplied,
    findConfiguration
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightMeercatWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const [itemsShown, setItemsShown] = useState(3)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.meercat,
      widgetPane: 'right'
    })
  }, [])

  const intl = useIntl()

  const headline = intl.formatMessage({
    id: 'meercat_on',
    defaultMessage: 'Related Solution Compass'
  })

  const defaultDescription = intl.formatMessage({
    id: 'meercat_default_description',
    defaultMessage: 'Please click link for further information.'
  })

  const seeMoreLink =
    findConfiguration &&
    findConfiguration.SeeAllLinks &&
    findConfiguration.SeeAllLinks[WidgetKey.meercat]
      ? findConfiguration.SeeAllLinks[WidgetKey.meercat]
      : 'https://spo-global.kpmg.com/sites/GO-OI-SVC-Advisory-Solutions-GlobalAdvisory'

  return (
    <Box
      id={'con-widget-right-solutioncompass'}
      className={
        meercatResults.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {meercatResults
        .slice(0, itemsShown)
        .map((item: IMeercatResult, index: number) => (
          <Box key={'rm' + index} className={rightWidgetClasses.widgetItem}>
            <Box key={'rmb1' + index} className={classes.widgetText}>
              <Link
                underline={'hover'}
                key={'rml1' + index}
                onClick={() => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackRightWidgetClick({
                    title: DOMPurify.sanitize(item.title),
                    url: DOMPurify.sanitize(item.link),
                    index,
                    widgetKey: WidgetKey.meercat
                  })
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        removeQueryParam(navigateFunction, 'cntx')
                        trackRightWidgetClick({
                          title: DOMPurify.sanitize(item.title),
                          url: DOMPurify.sanitize(item.link),
                          index,
                          widgetKey: WidgetKey.meercat,
                          wasRightClicked: event?.button === 2 ? true : false
                        })
                      }
                    }
                  : {})}
                href={DOMPurify.sanitize(item.link)}
                data-node-title={DOMPurify.sanitize(item.title)}
                data-node-index={index}
                data-node-click-type="rightWidgetClick"
                data-node-widget-key={WidgetKey.meercat}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(item.title)}
                  key={'rmd1' + index}
                  singleLine={true}
                  additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
                />
              </Link>
              <ResponsiveHTMLEllipsis
                className={`${rightWidgetClasses.widgetDescription} ${classes.widgetDescription}`}
                basedOn="words"
                unsafeHTML={highlightText(
                  item.description
                    ? DOMPurify.sanitize(item.description?.trim())
                    : defaultDescription,
                  prepareHighlightWords(
                    DOMPurify.sanitize(searchQuery),
                    item.description
                      ? DOMPurify.sanitize(item.description?.trim())
                      : defaultDescription,
                    synonymsApplied
                  ),
                  true
                )}
                maxLine="2"
              />
              <ResponsiveEllipsis
                className={rightWidgetClasses.widgetFooterContent}
                basedOn="words"
                text={DOMPurify.sanitize(item.country?.trim())}
                maxLine="1"
              />
            </Box>
          </Box>
        ))}
      {meercatResults.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={rightWidgetClasses.arrowContainer}
              tabIndex={0}
              onClick={showTen}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showTen()
                }
              }}
            >
              <KeyboardArrowDown className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={rightWidgetClasses.arrowContainerExpanded}
              tabIndex={0}
              onClick={showThree}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  showThree()
                }
              }}
            >
              <KeyboardArrowUp className={rightWidgetClasses.arrowItem} />
            </Grid>
          )}
          {meercatResults.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeMoreLink}
              widgetKey={WidgetKey.meercat}
              defaultText={
                <FormattedMessage id="see_more" defaultMessage="See More" />
              }
            />
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

export default connect(mapStateToProps)(RightMeercatWidget)
