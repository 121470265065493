import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterButton(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    rootLayout: {
      lineHeight: '13px',
      alignItems: 'flex-start',
      WebkitUserSelect: 'none',
      msUserSelect: 'none',
      marginRight: '16px',
      marginBottom: 'auto',
      flex: '1',
      flexGrow: 1,
      MozUserSelect: 'none',
      cursor: 'pointer',
      [`${theme.breakpoints.down(1024)}`]: {
        paddingLeft: '8px',
        paddingRight: '8px'
      },
      [`${theme.breakpoints.down(350)}`]: {
        paddingLeft: '0px',
        paddingRight: '0px'
      }
    },
    rootInactive: {
      color: '#5F6368',
      alignSelf: 'flex-end'
    },
    rootActive: {
      color: '#005eb8 !important'
    },
    rootNoResults: {
      color: '#dfe1e5'
    },

    icon: {
      marginRight: 5,
      height: 16,
      width: 16,
      marginTop: '1px',
      fontSize: '16px',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        display: 'none'
      }
    },
    linkText: {
      whiteSpace: 'pre',
      fontSize: 13,
      [`${theme.breakpoints.down(1024)}`]: {
        fontFamily:
          'Roboto-Medium,Roboto-Regular,Roboto-Medium,HelveticaNeue-Medium,HelveticaNeue,sans-serif-medium,Arial,sans-serif !important',
        textTransform: 'uppercase',
        fontWeight: 'lighter'
      }
    },
    // [`${theme.breakpoints.down(1024)}`]: {
    //   linkText: {
    //     fontSize: 13
    //   },
    //   rootActive: {},
    //   rootInactive: {}
    // },
    [`${theme.breakpoints.down(1024)}`]: {
      linkText: {
        fontSize: 12
      },
      rootActive: {},
      rootInactive: {}
    },
    FilterLink: {},
    loadingSpinner: {
      marginTop: '2px'
    }
  }))
}
