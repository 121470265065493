import React, { Dispatch, useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
// import LazyLoad from 'react-lazyload'
import { Box, IconButton, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { LeftArrow, RightArrow } from 'components/contents/common/Arrows'
import {
  trackException,
  trackWidgetShown,
  trackLeftWidgetClick,
  trackEvents
} from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { dateFormatOptions } from 'constants/constants'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftVideosWidget } from 'styles/contents/resultpages/widgets/LeftVideosWidget'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import VideoPlaceholder from 'images/kpmg_placeholder.png'
import VideoOverlay from 'images/video_overlay.png'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import AuthStore from 'store/Auth'
import { getPreviewThumbnail } from 'utils/msgraphThumbnailPicture'
import {
  getCountryKeyFromName,
  getUrlParameterForCurrentFilters
} from 'utils/filters'
import UserSettingsStore from 'store/UserSettings'
import FilterStore from 'store/Filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { IVideoResult } from 'components/models/VideoResult'
import { WidgetKey } from 'constants/widgets'
import prettyBytes from 'pretty-bytes'
import { getFileSize } from 'utils/o365'

export interface LeftVideoWidgetProps {
  query: string
  results: IVideoResult[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  LeftVideoWidgetProps

function LeftVideoWidget(props: AllProps): JSX.Element {
  const {
    results,
    deviceSettings,
    aadInfo,
    currentfilters,
    userSettings,
    clearTemporaryFilter,
    setTemporaryFilter,
    query
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()
  const classes = getStylesLeftVideosWidget()

  const [slideIndex, setSlideIndex] = useState(0)
  const [pictureUrls, setPictureUrls] = useState(
    results.map((item: IVideoResult) => {
      return item.Path
    })
  )

  const [resultsSize, setResultsSize] = useState(
    results.map((item: IVideoResult) => {
      return item.Size
    })
  )

  const intl = useIntl()

  const loadPictures = async () => {
    const newPictureUrls: string[] = []
    results.forEach(async (item: IVideoResult, i: number) => {
      const newPictureUrl = await getPreviewThumbnail(
        item.PictureThumbnailUrl,
        item.SiteID,
        item.WebID,
        item.ListID,
        item.ListItemID,
        deviceSettings,
        aadInfo
      )
      newPictureUrls.push(
        newPictureUrl ? newPictureUrl : item.PictureThumbnailUrl
      )

      if (i === 2 && !deviceSettings.isMobile) {
        setPictureUrls(newPictureUrls)
      }
    })

    setPictureUrls(newPictureUrls)
  }

  const loadVideosSize = async () => {
    const videoSizes: string[] = []
    results.forEach(async (item: IVideoResult, i: number) => {
      const size = await getFileSize(item.Path, aadInfo)
      videoSizes.push(size)

      if (i === 2 && !deviceSettings.isMobile) {
        setPictureUrls(videoSizes)
      }
    })
    setResultsSize(videoSizes)
  }

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: 'videos',
      widgetPane: 'left'
    })
    loadPictures()
    loadVideosSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    dragable: true,
    nextArrow:
      slideIndex + 3 >= results.length || deviceSettings.renderMobile ? (
        <></>
      ) : (
        <RightArrow />
      ),
    prevArrow:
      slideIndex === 0 || deviceSettings.renderMobile ? <></> : <LeftArrow />,
    afterChange: (newIndex: any) => setSlideIndex(newIndex)
  }

  let slides: JSX.Element[] = []
  try {
    slides = results.map((item: IVideoResult, i: number) => (
      // <LazyLoad offset={150}>
      <Box key={'lv' + i}>
        <Box key={'lvb1' + i} style={{ width: 20 }} />
        <Link
          href={DOMPurify.sanitize(item.Path)}
          key={'lvl' + i}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackLeftWidgetClick({
              title: DOMPurify.sanitize(item.Title),
              url: DOMPurify.sanitize(item.Path),
              widgetKey: 'videos',
              index: i
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackLeftWidgetClick({
                    title: DOMPurify.sanitize(item.Title),
                    url: DOMPurify.sanitize(item.Path),
                    widgetKey: 'videos',
                    index: i,
                    wasRightClicked: event?.button === 2 ? true : false
                  })
                }
              }
            : {})}
          className={leftWidgetClasses.cardLink}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <Card key={'lvc' + i} className={leftWidgetClasses.card}>
            <CardMedia
              key={'lvcm' + i}
              className={`${leftWidgetClasses.media} ${classes.media}`}
              sx={
                pictureUrls[i] && pictureUrls[i] !== ''
                  ? {
                      backgroundImage: `url("${DOMPurify.sanitize(
                        pictureUrls[i]
                      )}"), url("${VideoPlaceholder}") !important`,
                      '&:hover': {
                        backgroundImage: `url("${VideoOverlay}"), url("${DOMPurify.sanitize(
                          pictureUrls[i]
                        )}"), url("${VideoPlaceholder}") !important`
                      }
                    }
                  : {
                      backgroundImage: `url("${VideoPlaceholder}") !important`,
                      '&:hover': {
                        backgroundImage: `url("${VideoOverlay}"), url("${VideoPlaceholder}") !important`
                      }
                    }
              }
            >
              <Box key={'lvb2' + i} className={leftWidgetClasses.spacer} />
              <Box key={'lvb3' + i} className={leftWidgetClasses.spacer} />
            </CardMedia>
            <CardContent
              key={'lvcc' + i}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '5px'
              }}
            >
              <Typography
                key={'lvt1' + i}
                variant="body1"
                color="primary"
                component="div"
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(item.Title?.trim())}
                  key={'lvd1' + i}
                />
              </Typography>
              <Box key={'lvb4' + i} className={leftWidgetClasses.spacer} />
              <Typography
                key={'lvt2' + i}
                variant="caption"
                color="textSecondary"
                component="p"
                style={{ marginTop: 10, fontStyle: 'italic' }}
              >
                {intl.formatDate(
                  DOMPurify.sanitize(item.Date),
                  dateFormatOptions
                )}
              </Typography>
              <Typography
                key={'lvt3' + i}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {resultsSize[i] && resultsSize[i] !== '0'
                  ? prettyBytes(Number(resultsSize[i]))
                  : ''}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Box>
      //</LazyLoad>
    ))
  } catch (error) {
    trackException('Error in parsing slides in ResultsVideoFrame.tsx', error)
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box style={{ flex: '0 0 10px' }} />)
  }

  const seeAllLink = `/results/videos/${getUrlParameterForCurrentFilters(
    currentfilters,
    [{ key: 'page', value: '1' }]
  )}`

  // If widget prefiltering is enabled, add it to the see all link
  const setTemporaryFilters = () => {
    clearTemporaryFilter()
    let countryCode = 'none'
    if (
      userSettings &&
      userSettings.EnableWidgetPrefiltering === true &&
      userSettings.Country &&
      userSettings.Country !== ''
    ) {
      const countryKey = getCountryKeyFromName(userSettings.Country)
      countryCode = countryKey ? countryKey : countryCode
    }

    setTemporaryFilter({
      key: 'country',
      value:
        countryCode === 'none' ? countryCode : countryCode.toLocaleUpperCase()
    })

    KPMGFindGlobalVariables.setIsRedirectFromWidget(true)
  }

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            tabIndex={-1} //focus IconButton
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.video,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })

              setTemporaryFilters()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.video,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon></ArrowForwardIcon>
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  return (
    <Box id={'con-widget-left-video'} className={leftWidgetClasses.container}>
      {results && results.length > 0 && (
        <>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.video,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })

              setTemporaryFilters()
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.video,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h6"
              color="primary"
              component="p"
              className={leftWidgetClasses.link}
              gutterBottom
            >
              {intl.formatMessage({
                id: 'videos_on',
                defaultMessage: 'Related Videos'
              })}
            </Typography>
          </RouterLink>
          <Box className={leftWidgetClasses.cardContainer}>
            {!deviceSettings.renderMobile && slides.length > 0 && (
              <Slider className={leftWidgetClasses.slider} {...settings}>
                {slides}
              </Slider>
            )}
            {deviceSettings.renderMobile && slides.length > 0 && (
              <Box className={leftWidgetClasses.mobileScrollContainer}>
                {slides}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter()),
    setTemporaryFilter: (currentFilter: CurrentFilter) =>
      dispatch(FilterStore.actions.setTemporaryFilter(currentFilter))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftVideoWidget)
